import React, { FunctionComponent } from 'react'
import { Markup } from 'interweave'
import useTranslation from '../../hooks/useTranslation'
import Equipment from '../../entities/Equipment'
import InfoButton from '../info-button'
import InfoI from '../info-i'
import style from './equipmentTableRow.css'
import MediaImg from '../media-img'
import { EquipmentViewVariant } from './EquipmentTable'
import { Checkbox } from '../form'
import { Td, Tr } from '../table'
import ConflictResolutionLabel from './ConflictResolutionLabel'
import LockIcon from '../../assets/media/lock.svg'
import EquipmentInformation from '../../entities/EquipmentInformation'
import { RECOMMENDATION_STATUS_COMPLETE, RecommendationFamilyStatus } from '../../entities/RecommendationFamilyStatus'

export type Props = {
    selectEquipment: Function
    deselectEquipment: Function
    openInfoModal: Function
    equipment: Equipment
    viewVariant: EquipmentViewVariant
    priceColWidth?: string
    equipmentInformation: EquipmentInformation
    familyStatus: RecommendationFamilyStatus
}

const EquipmentTableRow: FunctionComponent<Props> = (props) => {
    const {
        equipment,
        deselectEquipment,
        selectEquipment,
        openInfoModal,
        viewVariant,
        priceColWidth,
        equipmentInformation,
        familyStatus,
    } = props
    const {
        id,
        name,
        detailedName,
        media,
        price,
        selected,
        stealth,
        isStandard,
        conflicted,
        selectable,
        replacedBy,
        evaKey,
        hidden,
        content,
        forced,
        recommended,
    } = equipment
    const { t } = useTranslation()

    if (hidden || stealth) {
        return null
    }

    const hasInfo = media && media.length > 0
    const infoIsForced = media && media.find((mediaItem) => mediaItem.forced) !== undefined

    const openModal = (activeMedia: string|null = null): Function => () => openInfoModal({
        title: name,
        media,
        activeMedia,
        content,
    })

    const changeHandler = (): void => {
        if (selected) {
            deselectEquipment(id)
        } else {
            const infoModal = infoIsForced ? {
                title: name,
                media,
                content,
            } : undefined

            selectEquipment(id, { infoModal })
        }
    }

    const labelClassName = [
        conflicted && viewVariant !== 'conflict' && viewVariant !== 'model_conflict' ? style.labelIsConflictual : '',
        replacedBy && replacedBy !== '' ? style.labelIsReplaced : '',
        detailedName ? style.detailedLabel : '',
        style.label,
    ].join(' ')

    const infoButtonElement = hasInfo ? (
        <InfoButton openModal={openModal()} dataCy={`info-button-${id.replace(/\\/g, '.')}`}>
            <InfoI isForced={infoIsForced} />
        </InfoButton>
    ) : null

    const imgElement = media && <MediaImg alt={name} media={media} width={100} />
    const imgInfoButtonElement = imgElement !== null ? (
        <InfoButton openModal={openModal('image')} dataCy={`info-img-button-${id.replace(/\\/g, '.')}`}>
            {imgElement}
        </InfoButton>
    ) : null

    const checkboxId = `checkbox-${id}`
    const hasCheckbox = viewVariant !== 'model_conflict'
    const labelFor = hasCheckbox ? checkboxId : null
    const checkbox = hasCheckbox ? (
        <Checkbox
            id={checkboxId}
            name="equipment"
            onChange={changeHandler}
            checked={selected}
            disabled={!selectable}
            className={style.checkbox}
            data-cy={`equipment-select-${id.replace(/\\/g, '.')}`}
        />
    ) : null

    const priceElement = isStandard
        ? t('standard-equipment-price')
        : <Markup content={price} noWrap />

    const priceColStyle = {
        minWidth: priceColWidth,
    }

    const lockIconElement = forced && selected ? <LockIcon className={style.lockIcon} /> : null

    const recommendedKey = forced ? 'equipmentSelection.mandatory' : recommended ? 'equipmentSelection.recommended' : ''

    const unselectedRecommendedEquipmentElement = ((forced || recommended) && !selected && familyStatus != RECOMMENDATION_STATUS_COMPLETE && !equipmentInformation.recommendations.selected) ?
        <div className={style.recommendedNotSelected}>{t(recommendedKey)}</div>
        : null

    const equipmentNameElement = detailedName ?
        <dl className={style.detailedList}>
            {Object.entries(detailedName).map(([key, value]) => (
                <div key={key} className={style.detailedName}>
                    <dt>{t(`equipmentSelection.detailedName.${key}`)}:</dt>
                    <dd>{value}</dd>
                </div>
            ))}
        </dl>
        : <Markup content={name}/>

    return (
        <Tr key={id}>
            <Td className={style.nameCol}>
                <div id={id} className="anchor" />
                <div className={style.nameColInner}>
                    <span>
                        {checkbox}
                    </span>
                    <span>
                        <label className={labelClassName} htmlFor={labelFor}>
                            <ConflictResolutionLabel
                                viewVariant={viewVariant}
                                selected={selected}
                            />
                            <span className={`${detailedName ? style.detailContainer : ''}`}>
                                {lockIconElement}
                                {equipmentNameElement}
                            </span>
                            <span className={`${detailedName ? style.displayIdDetailed : style.displayId}`}>
                                {evaKey}
                            </span>
                        </label>
                        {unselectedRecommendedEquipmentElement}
                    </span>
                    <span className={style.infoButtonWrapper}>
                        {imgInfoButtonElement}
                    </span>
                </div>
            </Td>
            <Td className={style.priceCol} style={priceColStyle}>
                <div className={style.priceContainer}>
                    {priceElement}
                </div>
                {infoButtonElement}
            </Td>
        </Tr>
    )
}

export default EquipmentTableRow
